:root {
  --mur-dark-blue: #0C0A3E;
  --mur-yellow: #DBD053;
  --mur-gold: #C89933;
  --mur-grey: #4F6D7A;
  --mur-blue: #C0D6DF;
  --white: #FFFFFF;

  --border-radius: 10px;
}

@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(./assets/fonts/Oswald-Regular.ttf) format('truetype');
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Oswald', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--mur-dark-blue);
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 28px;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}