.home {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home::after {
  content : "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('../../assets/images/software-developer_640.jpg');
  background-image: -webkit-image-set(
    url('../../assets/images/software-developer_640.jpg') 1x,
    url('../../assets/images/software-developer_1280.jpg') 2x);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  opacity : 0.2;
  z-index: -1;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.name {
  font-size: 60px;
  color: var(--mur-yellow);
  text-align: right;
}

.jobTitle {
  font-size: 30px;
  color: var(--mur-yellow);
  text-align: right;
}

.statement {
  font-size: 16px;
  color: var(--white);
  text-align: justify;
  margin-top: 45px;
  line-height: 24px;
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.contact {
  font-size: 16px;
  color: var(--white);
}

.contact a {
  color: inherit;
}

@media screen and (min-width: 480px) {
  .name {
    font-size: 80px;
  }
  
  .jobTitle {
    font-size: 40px;
  }

  .statement {
    font-size: 18px;
    margin-top: 45px;
    line-height: 27px;
  }
}


@media screen and (min-width: 768px) {
  .name {
    font-size: 100px;
  }
  
  .jobTitle {
    font-size: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .name {
    font-size: 120px;
  }
  
  .jobTitle {
    font-size: 60px;
  }

  .statement {
    font-size: 20px;
    margin-top: 45px;
    line-height: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .name {
    font-size: 144px;
  }
  
  .jobTitle {
    font-size: 72px;
  }
}
