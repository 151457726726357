.content-container {
  padding: 20px 20px;
}

@media screen and (min-width: 480px) {
  .content-container {
    padding: 20px 30px;
  }
}


@media screen and (min-width: 768px) {
  .content-container {
    padding: 20px 100px;
  }
}

@media screen and (min-width: 1024px) {
  .content-container {
    padding: 20px 130px;
  }
}

@media screen and (min-width: 1200px) {
  .content-container {
    padding: 20px 150px;
  }
}

@media screen and (min-width: 1400px) {
  .content-container {
    padding: 20px 200px;
  }
}